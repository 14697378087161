import React from "react";
import {AuthUser, AuthUserError} from "../../Store/LoadReducer";
import {useDispatch, useSelector} from "react-redux";
import AdminPanel from "../Templates/AdminPanel/AdminPanel";

function Admin(props) {
    const thisSite = useSelector(item => item.load.thisSite);
    const isAuth = useSelector(item => item.load.isAuth);
    const isAuthError = useSelector(item => item.load.isAuthError);
    const dispatch = useDispatch();
    const history = props.history;

    function authUser() {
        var login = document.getElementById("login");
        var password = document.getElementById("password");

        var formdata = new FormData();
        formdata.append("login", login.value);
        formdata.append("password", password.value);

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        fetch(thisSite + "/function/login.php", requestOptions)
            .then(response => response.json())
            .then(result => {
                    if (result.status === "error") {
                        dispatch(AuthUser(false));
                        dispatch(AuthUserError(true));
                    } else {
                        dispatch(AuthUser(result.status));
                        dispatch(AuthUserError(false));
                    }
                }
            );
    }

    return (
        isAuth ?
            <AdminPanel history={history}/>
            :
            <div className="position-fixed w-100">
                <div className="d-flex align-items-center vh-100">
                    <div className="me-auto ms-auto text-center">
                        <input id="login" type="text" className="form-control mb-2" placeholder="Логин"/>
                        <input id="password" type="password" className="form-control mb-2" placeholder="Пароль"/>
                        {
                            isAuthError && <p className="text-danger font-weight-bold">Ошибка авторизации</p>
                        }
                        <div className="d-grid">
                            <button className="btn btn-outline-primary" onClick={() => authUser()}>Войти</button>
                            <a className="btn btn-outline-success" href="/">На главную страницу</a>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default Admin;