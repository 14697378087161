import React from "react";
import "./Contact.css";
import place from './place.svg';
import tel from './tel.svg';
import mail from './mail.svg';
import globe from './globe.svg';
import {useSelector} from "react-redux";

function Contact() {
    const data = useSelector(item => item.load.data);
    const imgs = ["",place, tel, mail, globe];
    return (
        <div id="contact" className="contact-home-page pb-5">
            <div className="container">
                <h1 className="pb-5 text-white text-center">{data && data.title[7].text}</h1>

                <div className="d-flex align-items-center flex-wrap">

                    <iframe
                        src="https://yandex.ru/map-widget/v1/?um=constructor%3A7e6e71e0434b41a08cb3c4d9fb49ae91a302e3ef37056e2d4d39b8d47699dd8d&amp;source=constructor"
                        width="600" height="400" frameBorder="0"/>

                    <div className="contact-wrap">
                        <div className="contact-item pb-5">

                            {
                                data &&
                                    data.contact.map((cont) =>
                                        <h6 key={cont.id} className="text-white pb-2">
                                            <img src={imgs[cont.id]} alt="" className="contact-img"/>
                                            <a className="text-decoration-none text-white contact_a"
                                               href={cont.href}>{cont.title}</a>
                                        </h6>
                                    )
                            }

                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contact;