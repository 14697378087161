import {combineReducers, configureStore} from "@reduxjs/toolkit";
import LoadReducer from "./LoadReducer";

const rootReducer = combineReducers({
    load: LoadReducer
});

export const store = configureStore({
    reducer: rootReducer
});
