import React from "react";
import "./Area.css";
import {
    MDBCarousel,
    MDBCarouselInner,
    MDBCarouselItem,
    MDBCarouselElement
} from 'mdb-react-ui-kit';
import {useSelector} from "react-redux";

function Area() {
    const data = useSelector(item => item.load.data);
    return (
        <div id="area" className="area-home-page pb-5">
            <div className="container">
                <h1 className="pb-5 blue text-center">{data && data.title[3].text}</h1>

                <div className="area-wrap">
                    {
                        data &&
                        data.area.map((areas) =>
                            <div className="area-item" key={areas.id}>
                                <div className={"area-images area" + areas.id}/>
                                <p className="pt-2 blue text-center area-text">{areas.title}</p>
                            </div>
                        )
                    }
                </div>
                <div className="area-caorusel">
                    <MDBCarousel showControls fade>
                        <MDBCarouselInner>

                            {
                                data &&
                                data.area.map((areas) =>
                                    areas.id === "1" ?
                                        <MDBCarouselItem className='active'>
                                            <MDBCarouselElement
                                                src={document.location.origin + '/images/slider/' + areas.id + '.JPG'}/>
                                            <p  className="pt-2 blue text-center area-text">{areas.title}</p>
                                        </MDBCarouselItem> :
                                        <MDBCarouselItem>
                                            <MDBCarouselElement
                                                src={document.location.origin + '/images/slider/' + areas.id + '.JPG'}/>
                                            <p className="pt-2 blue text-center area-text">{areas.title}</p>
                                        </MDBCarouselItem>
                                )
                            }

                        </MDBCarouselInner>
                    </MDBCarousel>

                </div>
            </div>
        </div>
    );
}

export default Area;