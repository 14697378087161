import React, {useEffect} from "react";
import {Route, Switch} from "react-router-dom"
import {isLoadSwitch, setForms, setResult} from "./Store/LoadReducer";
import {useDispatch, useSelector} from "react-redux";
import Home from "./Componetns/Pages/Home";
import Loader from "./Componetns/Templates/Loader/Loader";
import Admin from "./Componetns/Pages/Admin";
import './App.css';

function App() {
    const thisSite = useSelector(item => item.load.thisSite);
    const data = useSelector(item => item.load.data);
    const dispatch = useDispatch();

    useEffect(() => {

        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(thisSite+"/api/select.php", requestOptions)
            .then(response => response.json())
            .then(result => {
                    setTimeout(() => {
                        dispatch(isLoadSwitch(result))
                    }, 1000);

                }
            );

    }, []);

    return (
        data ?
            <Switch>
                <Route exact path='/' render={(props) => <Home {...props}/>}/>
                <Route exact path='/admin' render={(props) => <Admin {...props}/>}/>
            </Switch> :
            <Loader/>
    );
}

export default App;
