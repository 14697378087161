import React from "react";
import "./ServiceHomePage.css";
import team from './team.svg';
import treap from './treap.svg'
import send from './send.svg';
import {useSelector} from "react-redux";

function ServiceHomePage() {
    const data = useSelector(item => item.load.data);
    const imgs = ["", team, treap, send];
    return (
        <div id="service" className="service-home-page pb-5">
            <div className="container">
                <h1 className="pb-5 blue text-center">{data && data.title[2].text}</h1>

                <div className="service-item-wrap pb-5">

                    {
                        data &&
                        data.service_card.map((service) =>
                            <div key={service.id} className="service-item">
                                <div className="img-service">
                                    <img className="w-100" src={imgs[service.id]}/>
                                </div>
                                <div className="service-text">
                                    <h4 className="blue pt-3">{service.title}<br/>{service.subtitle}</h4>
                                    <p>
                                        {service.aboutservice}
                                    </p>
                                </div>
                            </div>
                        )
                    }

                </div>

                <div className="service-about">
                    <p>
                        <b>{data && data.service[0].text}</b> {data && data.service[1].text}
                    </p>
                    <p>
                        <li>{data && data.service[2].text}</li>
                        <li>{data && data.service[3].text}</li>
                        <li>{data && data.service[4].text}</li>
                        <li>{data && data.service[5].text}</li>
                        <li>{data && data.service[6].text}</li>
                        <li>{data && data.service[7].text}</li>
                        <li>{data && data.service[8].text}</li>
                    </p>
                </div>
                <div className="service-say pb-3 pt-5">
                    <p>
                        <b>{data && data.service[9].text}</b> {data && data.service[10].text}
                    </p>
                </div>
                <div>
                    <h3 className="pb-3 blue text-center">{data && data.service[11].text}</h3>
                </div>
                <div className="service_buttons pb-3 w-50 ms-auto me-auto">
                    <div className="d-flex flex-wrap">
                        <a href="Breaf mice360.docx" download="Breaf mice360" className="btn btn-outline-primary me-auto ms-auto mb-3">
                            <h5 className="mb-1 mt-1">{data && data.service[12].text}</h5>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ServiceHomePage;