import React from "react";
import "./HomePage.css";
import {setForms} from "../../../Store/LoadReducer";
import {useDispatch, useSelector} from "react-redux";
import arrow from './arrow.svg';

function HomePage() {
    const data = useSelector(item => item.load.data);
    const dispatch = useDispatch();

    return (
        <div id="home" className="home-page">
            <div className="d-flex align-items-center h-100">
                <div className="blue-back w-40 h-100 me-auto">
                    <div>
                        <h1 className="text-white pb-5">{data && data.title[0].text}</h1>

                            <div className="d-flex pb-5 align-items-start">
                                <img className="arrow-home" src={arrow}/>
                                <h4 className="text-white h4-home">{data && data.home[0].data}</h4>
                            </div>

                            <div className="d-flex pb-5 align-items-start">
                                <img className="arrow-home" src={arrow}/>
                                <h4 className="text-white h4-home">
                                    {data && data.home[1].data}<br/>{data && data.home[2].data}<br/>
                                    {data && data.home[3].data}
                                </h4>
                            </div>

                        <div className="d-flex pb-5 align-items-start">
                            <img className="arrow-home" src={arrow}/>
                            <h4 className="text-white h4-home">
                                {data && data.home[4].data}<br/> {data && data.home[5].data}
                            </h4>
                        </div>

                        <button className="btn btn-outline-light blue-btns"
                                onClick={() => dispatch(setForms(true))}>
                            <h5 className="mb-1 mt-1">{data && data.home[6].data}</h5>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomePage;