import React from "react";
import Menu from "../Menu/Menu";
import "./Header.css";
import logo from './logo.svg';
import phone from './phone.svg';
import {useDispatch, useSelector} from "react-redux";
import {setMenu} from "../../../Store/LoadReducer";

function Header(props) {
    const data = useSelector(item => item.load.data);
    const history = props.history;
    const dispatch = useDispatch();

    return (
        <header>
            <div className="container-fluid">
                <div className="d-flex align-items-center">
                    <div className="container">
                        <div className="d-flex align-items-center">
                            <div className="logo">
                                <a href="/"><img className="w-100" src={logo} alt="MICE 360"/></a>
                            </div>
                            <a href={data && "tel:" + data.header[0].data} className="header_phone">
                                <img className="phone_header" src={phone} alt=""/>{data && data.header[0].data}
                            </a>
                        </div>
                    </div>
                    <div className="else-toogle"/>
                    <div className="nav_toogle" onClick={() => dispatch(setMenu())}>
                        <div className="burger"/>
                        <div className="burger"/>
                        <div className="burger"/>
                    </div>
                </div>
            </div>
            <Menu admin={props.admin} history={history}/>
        </header>
    );
}

export default Header;