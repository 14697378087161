import React from "react";
import "./Menu.css";
import {useDispatch, useSelector} from "react-redux";
import {setMenu} from "../../../Store/LoadReducer";

function Menu(props) {
    const dispatch = useDispatch();
    const isMenu = useSelector(item => item.load.isMenu);
    const data = useSelector(item => item.load.data);
    const history = props.history;
    const hash = history.location.hash;

    return (
        <nav className={isMenu ? "active" : undefined}>
            {
                data &&
                data.nav.map((nav) =>
                    <a key={nav.id} onClick={() => dispatch(setMenu())}
                       className={hash === nav.href ? "nav-item active" : "nav-item"}
                       href={nav.href}>{nav.name}</a>
                )
            }
            <div className="nav_toogle_close" onClick={() => dispatch(setMenu())}>
                <div className="burger l"/>
                <div className="burger r"/>
            </div>
        </nav>
    );
}

export default Menu;