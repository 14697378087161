import React from "react";
import './Loader.css';
import logo from './logo.svg';

function Loader() {
    return (
        <div className="position-fixed w-100 loader-wrap">
            <div className="d-flex align-items-center vh-100">
                <img className="img-fluid animate ms-auto me-auto" src={logo} alt="Loading"/>
            </div>
        </div>
    )
}

export default Loader;