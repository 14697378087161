import React, {useState} from "react";
import './AdminPanel.css';
import {AuthUser, setData} from "../../../Store/LoadReducer";
import {useDispatch, useSelector} from "react-redux";

function AdminPanel() {
    const data = useSelector(item => item.load.data);
    const thisSite = useSelector(item => item.load.thisSite);
    const dispatch = useDispatch();

    const [header, setHeader] = useState(false);
    const [nav, setNav] = useState(false);
    const [home, setHome] = useState(false);
    const [title, setTitle] = useState(false);
    const [about, setAbout] = useState(false);
    const [service, setService] = useState(false);
    const [area, setArea] = useState(false);
    const [preference, setPreference] = useState(false);
    const [contact, setContact] = useState(false);

    function exitUser() {
        window.sessionStorage.clear();
        dispatch(AuthUser(false))
    }

    function changeInput(e, p, id) {
        var n = e.target.value;
        dispatch(setData({
            new_data: n,
            parent: p,
            id: id
        }));
    }
    
    function save() {
        var formdata = new FormData();
        formdata.append("data", JSON.stringify(data));

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow',
        };

        fetch(thisSite+"/api/save.php", requestOptions)
            .then(response => response.text())
            .then(result => {
                    if(result === "ok") {
                        window.location.reload();
                    }
                }
            );
    }

    return (
        <div>
            <div id="admin-panel" className="pt-2 pb-2 d-flex bg-dark align-items-center">
                <p className="mb-0 ms-4 text-white">Режим редактирования</p>
                <button className="btn btn-success me-auto ms-2" onClick={() => save()}>Сохранить</button>
                <button className="btn btn-danger ms-auto me-2" onClick={() => exitUser()}>Выход</button>
            </div>
            <div className="container pt-5 mt-2">

                <div className={header ? "active card p-2 m-1" : "card p-2 m-1"}>
                    <div className="d-flex align-items-center">
                        <h4>Верхнее меню</h4>
                        {
                            header ?
                                <button className="btn btn-success ms-auto mb-2"
                                        onClick={() => setHeader(false)}>Свернуть</button> :
                                <button className="btn btn-success ms-auto mb-2"
                                        onClick={() => setHeader(true)}>Развернуть</button>
                        }
                    </div>

                    {
                        data &&
                        data.header.map((h) =>
                            <input key={h.id}
                                   type="text"
                                   defaultValue={h.data}
                                   onChange={(e) => changeInput(e, "header", h.id)}
                            />
                        )
                    }
                </div>

                <div className={nav ? "active card p-2 m-1" : "card p-2 m-1"}>
                    <div className="d-flex align-items-center">
                        <h4>Навигация</h4>
                        {
                            nav ?
                                <button className="btn btn-success ms-auto mb-2"
                                        onClick={() => setNav(false)}>Свернуть</button> :
                                <button className="btn btn-success ms-auto mb-2"
                                        onClick={() => setNav(true)}>Развернуть</button>
                        }
                    </div>
                    {
                        data &&
                        data.nav.map((h) =>
                            <div className="d-grid p-1 m-1" key={h.id}>
                                <input
                                    type="text"
                                    defaultValue={h.name}
                                    onChange={(e) => changeInput(e, "navname", h.id)}
                                />
                                <input
                                    type="text"
                                    defaultValue={h.href}
                                    onChange={(e) => changeInput(e, "navhref", h.id)}
                                />
                            </div>
                        )
                    }
                </div>

                <div className={title ? "active card p-2 m-1" : "card p-2 m-1"}>
                    <div className="d-flex align-items-center">
                        <h4>Заголовки</h4>
                        {
                            title ?
                                <button className="btn btn-success ms-auto mb-2"
                                        onClick={() => setTitle(false)}>Свернуть</button> :
                                <button className="btn btn-success ms-auto mb-2"
                                        onClick={() => setTitle(true)}>Развернуть</button>
                        }
                    </div>
                    {
                        data &&
                        data.title.map((h) =>
                            <div className="d-grid p-1 m-1" key={h.id}>
                                <input
                                    type="text"
                                    defaultValue={h.text}
                                    onChange={(e) => changeInput(e, "title", h.id)}
                                />
                            </div>
                        )
                    }
                </div>

                <div className={home ? "active card p-2 m-1" : "card p-2 m-1"}>
                    <div className="d-flex align-items-center">
                        <h4>Главный экран</h4>
                        {
                            home ?
                                <button className="btn btn-success ms-auto mb-2"
                                        onClick={() => setHome(false)}>Свернуть</button> :
                                <button className="btn btn-success ms-auto mb-2"
                                        onClick={() => setHome(true)}>Развернуть</button>
                        }
                    </div>
                    {
                        data &&
                        data.home.map((h) =>
                            <div className="d-grid p-1 m-1" key={h.id}>
                                <input key={h.id}
                                       type="text"
                                       defaultValue={h.data}
                                       onChange={(e) => changeInput(e, "home", h.id)}
                                />
                            </div>
                        )
                    }
                </div>

                <div className={about ? "active card p-2 m-1" : "card p-2 m-1"}>
                    <div className="d-flex align-items-center">
                        <h4>О компании</h4>
                        {
                            about ?
                                <button className="btn btn-success ms-auto mb-2"
                                        onClick={() => setAbout(false)}>Свернуть</button> :
                                <button className="btn btn-success ms-auto mb-2"
                                        onClick={() => setAbout(true)}>Развернуть</button>
                        }
                    </div>
                    {
                        data &&
                        data.about.map((h) =>
                            <div className="d-grid p-1 m-1" key={h.id}>
                                <input key={h.id}
                                       type="text"
                                       defaultValue={h.text}
                                       onChange={(e) => changeInput(e, "about", h.id)}
                                />
                            </div>
                        )
                    }
                </div>

                <div className={service ? "active card p-2 m-1" : "card p-2 m-1"}>
                    <div className="d-flex align-items-center">
                        <h4>Услуги</h4>
                        {
                            service ?
                                <button className="btn btn-success ms-auto mb-2"
                                        onClick={() => setService(false)}>Свернуть</button> :
                                <button className="btn btn-success ms-auto mb-2"
                                        onClick={() => setService(true)}>Развернуть</button>
                        }
                    </div>
                    {
                        data &&
                        data.service_card.map((h) =>
                            <div className="d-grid p-1 m-1" key={h.id}>
                                <input
                                    type="text"
                                    defaultValue={h.title}
                                    onChange={(e) => changeInput(e, "service_card", h.id)}
                                />
                                <input
                                    type="text"
                                    defaultValue={h.subtitle}
                                    onChange={(e) => changeInput(e, "service_cards", h.id)}
                                />
                            </div>
                        )
                    }
                    {
                        data &&
                        data.service.map((h) =>
                            <div className="d-grid p-1 m-1" key={h.id}>
                                <input
                                    type="text"
                                    defaultValue={h.text}
                                    onChange={(e) => changeInput(e, "service", h.id)}
                                />
                            </div>
                        )
                    }
                </div>

                <div className={area ? "active card p-2 m-1" : "card p-2 m-1"}>
                    <div className="d-flex align-items-center">
                        <h4>Площадки</h4>
                        {
                            area ?
                                <button className="btn btn-success ms-auto mb-2"
                                        onClick={() => setArea(false)}>Свернуть</button> :
                                <button className="btn btn-success ms-auto mb-2"
                                        onClick={() => setArea(true)}>Развернуть</button>
                        }
                    </div>
                    {
                        data &&
                        data.area.map((h) =>
                            <div className="d-grid p-1 m-1" key={h.id}>
                                <input key={h.id}
                                       type="text"
                                       defaultValue={h.title}
                                       onChange={(e) => changeInput(e, "area", h.id)}
                                />
                            </div>
                        )
                    }
                </div>

                <div className={preference ? "active card p-2 m-1" : "card p-2 m-1"}>
                    <div className="d-flex align-items-center">
                        <h4>Преимущества</h4>
                        {
                            preference ?
                                <button className="btn btn-success ms-auto mb-2"
                                        onClick={() => setPreference(false)}>Свернуть</button> :
                                <button className="btn btn-success ms-auto mb-2"
                                        onClick={() => setPreference(true)}>Развернуть</button>
                        }
                    </div>
                    {
                        data &&
                        data.preference_card.map((h) =>
                            <div className="d-grid p-1 m-1" key={h.id}>
                                <input
                                    type="text"
                                    defaultValue={h.title}
                                    onChange={(e) => changeInput(e, "preference_card", h.id)}
                                />
                                <input
                                    type="text"
                                    defaultValue={h.subtitle}
                                    onChange={(e) => changeInput(e, "preference_cards", h.id)}
                                />
                            </div>
                        )
                    }
                    {
                        data &&
                        data.preference.map((h) =>
                            <div className="d-grid p-1 m-1" key={h.id}>
                                <input
                                    type="text"
                                    defaultValue={h.text}
                                    onChange={(e) => changeInput(e, "preference", h.id)}
                                />
                            </div>
                        )
                    }
                </div>

                <div className={contact ? "active card p-2 m-1" : "card p-2 m-1"}>
                    <div className="d-flex align-items-center">
                        <h4>Контакты</h4>
                        {
                            contact ?
                                <button className="btn btn-success ms-auto mb-2"
                                        onClick={() => setContact(false)}>Свернуть</button> :
                                <button className="btn btn-success ms-auto mb-2"
                                        onClick={() => setContact(true)}>Развернуть</button>
                        }
                    </div>
                    {
                        data &&
                        data.contact.map((h) =>
                            <div className="d-grid p-1 m-1" key={h.id}>
                                <input
                                    type="text"
                                    defaultValue={h.title}
                                    onChange={(e) => changeInput(e, "contact", h.id)}
                                />
                                <input
                                    type="text"
                                    defaultValue={h.href}
                                    onChange={(e) => changeInput(e, "contacts", h.id)}
                                />
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default AdminPanel;