import {createSlice} from "@reduxjs/toolkit";

const load = createSlice({
    name: "load",
    initialState: {
        //thisSite: "http://simpleapi",
        thisSite: "https://mice360.ru",
        isAuth: false,
        isAuthError: false,
        isLoad: false,
        isMenu: false,
        isForms: false,
        isResult: false,
        data: false
    },
    reducers: {
        setData(state, action) {
            var new_data = action.payload.new_data;
            var parent = action.payload.parent;
            var id = action.payload.id;

            if (parent === "header") {
                state.data.header[0].data = new_data;
            } else if (parent === "navname") {
                var find = state.data.nav.find(item => item.id === id);
                find.name = new_data;
            } else if (parent === "navhref") {
                var find = state.data.nav.find(item => item.id === id);
                find.href = new_data;
            } else if (parent === "home") {
                var find = state.data.home.find(item => item.id === id);
                find.data = new_data;
            } else if (parent === "title") {
                var find = state.data.title.find(item => item.id === id);
                find.text = new_data;
            } else if (parent === "about") {
                var find = state.data.about.find(item => item.id === id);
                find.text = new_data;
            } else if (parent === "service") {
                var find = state.data.service.find(item => item.id === id);
                find.text = new_data;
            } else if (parent === "service_card") {
                var find = state.data.service_card.find(item => item.id === id);
                find.title = new_data;
            } else if (parent === "service_cards") {
                var find = state.data.service_card.find(item => item.id === id);
                find.subtitle = new_data;
            } else if (parent === "area") {
                var find = state.data.area.find(item => item.id === id);
                find.title = new_data;
            } else if (parent === "preference_card") {
                var find = state.data.preference_card.find(item => item.id === id);
                find.title = new_data;
            } else if (parent === "preference_cards") {
                var find = state.data.preference_card.find(item => item.id === id);
                find.subtitle = new_data;
            } else if (parent === "preference") {
                var find = state.data.preference.find(item => item.id === id);
                find.text = new_data;
            } else if (parent === "contact") {
                var find = state.data.contact.find(item => item.id === id);
                find.title = new_data;
            } else if (parent === "contacts") {
                var find = state.data.contact.find(item => item.id === id);
                find.href = new_data;
            }
        },
        AuthUserError(state, action) {
            state.isAuthError = action.payload;
        },
        AuthUser(state, action) {
            window.sessionStorage.setItem("token", action.payload);
            state.isAuth = action.payload;
        },
        isLoadSwitch(state, action) {
            var token = window.sessionStorage.getItem("token");

            if (token !== "false") {
                state.isAuth = token;
            }
            state.data = action.payload;
        },
        setMenu(state) {
            if (state.isMenu) {
                state.isMenu = false;
            } else {
                state.isMenu = true;
            }
        },
        setForms(state, action) {
            state.isForms = action.payload;
        },
        setResult(state, action) {
            state.isResult = action.payload;
        },
    }
});

export default load.reducer;
export const {
    isLoadSwitch,
    setMenu,
    setForms,
    setResult,
    AuthUser,
    AuthUserError,
    setData
} = load.actions;
