import React from "react";
import Header from "../Templates/Header/Header";
import HomePage from "../Templates/HomePage/HomePage";
import AboutHomePage from "../Templates/AboutHomePage/AboutHomePage";
import ServiceHomePage from "../Templates/ServiceHomePage/ServiceHomePage";
import Area from "../Templates/Area/Area";
import Forms from "../Templates/Forms/Forms";
import Preference from "../Templates/Preference/Preference";
import Contact from "../Templates/Contact/Contact";

function Home(props) {

    const history = props.history;

    return (
        <div>
            <Header history={history}/>
            <main>
                <HomePage/>
                <AboutHomePage/>
                <ServiceHomePage/>
                <Area/>
                <Preference/>
            </main>
            <footer>
                <Contact/>
            </footer>
            <Forms/>
        </div>
    );
}

export default Home;