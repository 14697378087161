import React from "react";
import "./Forms.css";
import logo from './logo.svg';
import {useDispatch, useSelector} from "react-redux";
import {setForms, setResult} from "../../../Store/LoadReducer";

function Forms() {
    const thisSite = useSelector(item => item.load.thisSite);
    const isForms = useSelector(item => item.load.isForms);
    const isResult = useSelector(item => item.load.isResult);
    const dispatch = useDispatch();

    function setPost() {

        var name = document.getElementById("form-name");
        var company = document.getElementById("form-company");
        var phone = document.getElementById("form-phone");
        var mail = document.getElementById("form-mail");
        var format = document.getElementById("form-format");
        var yes = document.getElementById("form-yes");
        var yesLabel = document.getElementById("form-yes-label");
        var robot = document.getElementById("form-robot");
        var robotLabel = document.getElementById("form-robot-label");

        var nameCheck = errorView(checkInputLength(name.value), name);
        var phoneCheck = errorView(checkInputPhoneLength(phone.value), phone);
        var mailCheck = errorView(checkInputMail(mail.value), mail);
        var yesCheck = errorViewLabel(checkInputYes(yes.checked), yesLabel);
        var robotCheck = errorViewLabel(checkInputYes(robot.checked), robotLabel);

        if (nameCheck &&
            phoneCheck &&
            mailCheck &&
            yesCheck &&
            robotCheck) {

            var formdata = new FormData();
            formdata.append("name", name.value);
            formdata.append("company", company.value);
            formdata.append("phone", phone.value);
            formdata.append("mail", mail.value);
            formdata.append("format", format.value);

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch(thisSite+"/mail.php", requestOptions)
                .then(response => response.text())
                .then(result => {
                        if (result === "ok") {
                            dispatch(setResult(true));
                        }
                    }
                );


            setTimeout(() => {
                dispatch(setForms(false));
            }, 2000);

            setTimeout(() => {
                dispatch(setResult(false));
            }, 2500);
        }

    }

    function checkInputYes(a) {
        return !a
    }

    function checkInputLength(a) {
        return a.length < 1;
    }

    function checkInputMail(a) {
        return a.indexOf('@') <= 0;
    }

    function checkInputPhoneLength(a) {
        return a.length < 11;
    }

    function errorView(a, n) {
        if (a) {
            n.classList.add("form-error");
            return false;
        } else {
            n.classList.remove("form-error");
            return true;
        }
    }

    function errorViewLabel(a, n) {
        if (a) {
            n.classList.add("form-error-label");
            return false;
        } else {
            n.classList.remove("form-error-label");
            return true;
        }
    }

    return (
        <div id="forms-inset" className={isForms ? "forms active" : "forms"}>
            {isResult ?
                <div className="form-wrap">
                    <div className="form-text text-center pb-2">
                        <img className="w-25" src={logo} alt=""/>
                    </div>
                    <div className="text-center">
                        <h4 className="blue">Заявка успешно отправлена!</h4>
                        <h6>Наш специалист свяжется с Вами в ближайшее время</h6>
                    </div>
                </div>
                :
                <div className="form-wrap">
                    <div className="close" onClick={() => dispatch(setForms(false))}>
                        <div className="nav_toogle_close">
                            <div className="burger l"></div>
                            <div className="burger r"></div>
                        </div>
                    </div>
                    <div className="form-text text-center pb-2">
                        <img className="w-25" src={logo} alt=""/>
                    </div>
                    <div className="form-text">
                        <input id="form-name" className="form-control" type="text" name="name" placeholder="Ваше имя*"/>
                    </div>
                    <div className="form-text">
                        <input id="form-company" className="form-control" type="text" name="company"
                               placeholder="Название компании"/>
                    </div>
                    <div className="form-text">
                        <input id="form-phone" className="form-control" type="tel" name="phone" placeholder="Телефон*"/>
                    </div>
                    <div className="form-text">
                        <input id="form-mail" className="form-control" type="email" name="email" placeholder="Email*"/>
                    </div>
                    <div className="form-text">
                        <textarea id="form-format" className="form-control" name="format"
                                  placeholder="Формат мероприятия"/>
                    </div>
                    <div className="form-text">
                        <div className="d-flex align-items-center">
                            <small className="mb-0">*Обязательные поля</small>
                        </div>
                    </div>
                    <div className="form-text">
                        <div className="d-flex align-items-center">
                            <label htmlFor="form-yes">
                                <input id="form-yes" className="me-2" type="checkbox" name="yes"/>
                                <small id="form-yes-label" className="mb-0">Согласие на обработку персональных
                                    данных</small>
                            </label>
                        </div>
                    </div>
                    <div className="form-text">
                        <div className="d-flex align-items-center">
                            <label htmlFor="form-robot">
                                <input id="form-robot" className="me-2" type="checkbox" name="robot"/>
                                <small id="form-robot-label" className="mb-0">Я не робот</small>
                            </label>
                        </div>
                    </div>
                    <div className="form-text text-center">
                        <button className="btn btn-outline-primary" onClick={() => setPost()}>ОТПРАВИТЬ</button>
                    </div>
                </div>
            }
        </div>
    );
}

export default Forms;