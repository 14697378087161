import React from "react";
import "./AboutHomePage.css";
import {useSelector} from "react-redux";

function AboutHomePage() {
    const data = useSelector(item => item.load.data);
    return (
        <div id="about" className="about-home-page">
            <div className="container-fluid">
                <div className="item-about">
                    <div className="text-center">
                        <h1 className="pb-5 blue">{data && data.title[1].text}</h1>
                    </div>
                    <p>
                        <b>{data && data.about[0].text}</b>{data && data.about[1].text}
                    </p>
                    <p>
                        {data && data.about[2].text} <b>{data && data.about[3].text}</b> {data && data.about[4].text}
                    </p>
                    <p>
                        <b>{data && data.about[5].text}</b>
                        <li>{data && data.about[6].text}</li>
                        <li>{data && data.about[7].text}</li>
                    </p>
                    <p>
                        <b>{data && data.about[8].text}</b>{data && data.about[9].text}
                    </p>
                    <p>
                        {data && data.about[10].text}
                        <li>{data && data.about[11].text}</li>
                        <li>{data && data.about[12].text}</li>
                        <li>{data && data.about[13].text}</li>
                        <li>{data && data.about[14].text}</li>
                    </p>
                    <p>
                        <b>{data && data.about[15].text}</b>{data && data.about[16].text}
                    </p>
                    <p><b>{data && data.about[17].text}</b></p>
                </div>
            </div>
        </div>
    );
}

export default AboutHomePage;