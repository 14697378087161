import React from "react";
import "./Preference.css";
import cool from './images/cool.svg';
import walet from './images/walet.svg';
import puzzle from './images/puzzle.svg';
import find from './images/find.svg';
import {setForms, setResult} from "../../../Store/LoadReducer";
import {useDispatch, useSelector} from "react-redux";

function Preference() {
    const data = useSelector(item => item.load.data);
    const dispatch = useDispatch();

    function setPosts() {

        var forms = document.getElementById('forms-inset');

        var name = document.getElementById("form-name-preference");
        var company = document.getElementById("form-company-preference");
        var phone = document.getElementById("form-phone-preference");
        var mail = document.getElementById("form-mail-preference");
        var format = document.getElementById("form-format-preference");
        var yes = document.getElementById("form-yes-preference");
        var yesLabel = document.getElementById("form-yes-label-preference");
        var robot = document.getElementById("form-robot-preference");
        var robotLabel = document.getElementById("form-robot-label-preference");

        var nameCheck = errorView(checkInputLength(name.value), name);
        var phoneCheck = errorView(checkInputPhoneLength(phone.value), phone);
        var mailCheck = errorView(checkInputMail(mail.value), mail);
        var yesCheck = errorViewLabel(checkInputYes(yes.checked), yesLabel);
        var robotCheck = errorViewLabel(checkInputYes(robot.checked), robotLabel);

        if (nameCheck &&
            phoneCheck &&
            mailCheck &&
            yesCheck &&
            robotCheck) {

            var formdata = new FormData();
            formdata.append("name", name.value);
            formdata.append("company", company.value);
            formdata.append("phone", phone.value);
            formdata.append("mail", mail.value);
            formdata.append("format", format.value);

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch("https://mice360.ru/mail.php", requestOptions)
                .then(response => response.text())
                .then(result => {
                        if (result === "ok") {
                            forms.classList.add("active");
                            dispatch(setResult(true));
                        }
                    }
                );

            setTimeout(() => {
                forms.classList.remove("active");
                dispatch(setForms(false));
            }, 2000);

            setTimeout(() => {
                forms.classList.remove("active");
                dispatch(setResult(false));
            }, 2500);

        }

    }

    function checkInputYes(a) {
        return !a
    }

    function checkInputLength(a) {
        return a.length < 1;
    }

    function checkInputMail(a) {
        return a.indexOf('@') <= 0;
    }

    function checkInputPhoneLength(a) {
        return a.length < 11;
    }

    function errorView(a, n) {
        if (a) {
            n.classList.add("form-error");
            return false;
        } else {
            n.classList.remove("form-error");
            return true;
        }
    }

    function errorViewLabel(a, n) {
        if (a) {
            n.classList.add("form-error-label");
            return false;
        } else {
            n.classList.remove("form-error-label");
            return true;
        }
    }

    const imgs = ["", find, cool, puzzle, walet];
    return (
        <div id="preference" className="preference-home-page pt-5 pb-5">
            <div className="container">
                <h1 className="pb-5 blue text-center">{data && data.title[4].text}</h1>
                <div className="preference-wrap">

                    <div className="d-flex flex-wrap">

                        {
                            data &&
                                data.preference_card.map((pref) =>
                                    <div key={pref.id} className="pref-item">
                                        <div className="d-flex align-items-center h-100 card768">
                                            <div className="pref_img pb-2 ms-3">
                                                <img src={imgs[pref.id]}/>
                                            </div>
                                            <div className="ms-5 ms-5-768">
                                                <h3 className="blue">{pref.title}</h3>
                                                <p className="pref-text">{pref.subtitle}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                        }

                    </div>
                    <div className="form-wrap-pref">
                        <h1 className="pt-5 pb-5 me-auto light-blue">{data && data.title[5].text}<br/> {data && data.title[6].text}</h1>
                        <div className="right-form-preference">
                            <div className="form-text">
                                <input id="form-name-preference" className="form-control" type="text" name="name"
                                       placeholder="Ваше имя"/>
                            </div>
                            <div className="form-text">
                                <input id="form-company-preference" className="form-control" type="text" name="company"
                                       placeholder="Название компании"/>
                            </div>
                            <div className="form-text">
                                <input id="form-phone-preference" className="form-control" type="tel" name="phone"
                                       placeholder="Телефон"/>
                            </div>
                            <div className="form-text">
                                <input id="form-mail-preference" className="form-control" type="email" name="email"
                                       placeholder="Email"/>
                            </div>
                            <div className="form-text">
                            <textarea id="form-format-preference" className="form-control" rows="10" name="format"
                                      placeholder="Формат мероприятия"/>
                            </div>
                            <div className="form-text">
                                <div className="d-flex align-items-center">
                                    <label htmlFor="form-yes-preference">
                                        <input id="form-yes-preference" className="me-2" type="checkbox" name="yes"/>
                                        <small id="form-yes-label-preference" className="mb-0">{data && data.preference[0].text}</small>
                                    </label>
                                </div>
                            </div>
                            <div className="form-text">
                                <div className="d-flex align-items-center">
                                    <label htmlFor="form-robot-preference">
                                        <input id="form-robot-preference" className="me-2" type="checkbox"
                                               name="robot"/>
                                        <small id="form-robot-label-preference" className="mb-0">{data && data.preference[1].text}</small>
                                    </label>
                                </div>
                            </div>
                            <div className="form-text text-center">
                                <button className="btn btn-outline-primary" onClick={() => setPosts()}>{data && data.preference[2].text}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Preference;